<template>
  <div class="home-page">
    <app-header></app-header>
    <billboard-main></billboard-main>
    <career-agent-info></career-agent-info>
    <job-collection></job-collection>
    <tech-stack-selection></tech-stack-selection>
    <customer-quote></customer-quote>
    <pre-footer></pre-footer>
    <faq></faq>
    <Footer></footer>
  </div>
</template>

<script>
export default {
  components: {
    AppHeader: () => import("@/router/content-blocks/app-header"),
    BillboardMain: () => import("@/router/content-blocks/billboard-main"),
    CareerAgentInfo: () => import("@/router/content-blocks/career-agent-info"),
    JobCollection: () => import("@/router/content-blocks/job-collection"),
    TechStackSelection: () => import("@/router/content-blocks/tech-stack-selection"),
    CustomerQuote: () => import("@/router/content-blocks/customer-quote"),
    PreFooter: () => import("@/router/content-blocks/pre-footer"),
    Faq: () => import("@/router/content-blocks/faq"),
    Footer: () => import("@/router/content-blocks/footer"),
  },
  data() {
   
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss">
.home-page {
    margin-top: calc(var(--app-header-height)*.95);
}
</style>