<template>
  <div>
    <!-- <app-header></app-header> -->
    <chat-bot bot_id="H-1082496-G7347K34IRWMPL0K"></chat-bot>
    <!-- <Footer></footer> -->
  </div>
</template>

<script>
import ChatBot from '../../components/chat-bot/chat-bot.vue';
export default {
  components: {
    // AppHeader: () => import("@/router/content-blocks/app-header"),
    ChatBot: () => import('@/components/chat-bot/chat-bot.vue'),
    // Footer: () => import('@/router/content-blocks/footer'),
  },
  data() {
    return {}
  },
  mounted() {
      this.$store.dispatch('app/LOADED')
  },
};
</script>

<style lang="scss">
.home-page {
    margin-top: calc(var(--app-header-height)*.95);
}
</style>