<template>
  <div>
    <div id="myLandbot" style="width: 100%; height: 500px"></div>
  </div>
</template>
<script>
let TheLandbot = null;

export default {
  data: () => ({
    TheLandbot: null,
  }),
  props: {
    bot_id: {
        type: String,
        default: 'H-1008497-2X0DUADCUFWH0OO4'
    },
    ui_job_selection: String,
    ui_tech_stack_selection: String,
  },
  mounted() {
    this.$nextTick(function () {
      this.init();
      console.log("chat-bot.vue, bot_id", this.bot_id)
    });
  },
  methods: {
    init() {
      TheLandbot = new Landbot.Container({
        container: "#myLandbot",
        configUrl:
          `https://chats.landbot.io/v3/${this.bot_id}/index.json`,
        customData: {
          ui_job_selection: this.ui_job_selection,
          ui_tech_stack_selection: this.ui_tech_stack_selection,
        },
      });
    },
    destroy() {
      TheLandbot.destroy();
      TheLandbot = null;
    },
  },
  beforeDestroy() {
    this.destroy();
  },
  destroyed() {
    // this.TheLandbot.destroy()
  },
};
</script>
<style lang="scss">
</style>